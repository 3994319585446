.bg-black {
    background-color: black;
    color: white;
}

.txt-red {
    color: red;
}
.txt-purple {
    color: purple;
}
.txt-green {
    color: green;
}
.txt-orange {
    color: orange;
}
.txt-pink {
    color: pink;
}